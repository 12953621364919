










































































































































































import { Vue, Component } from "vue-property-decorator";
import { aemetConfig } from "@/config/aemet";
import { mapMutations } from "vuex";
import * as types from "@/store/types";

@Component({
    components: {
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInput.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class RangeWeather extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    especificDayIdema = [
        "7002Y - ÁGUILAS",
        "7012C - CARTAGENA",
        "7031 - SAN JAVIER AEROPUERTO",
        "7031X - SAN JAVIER AEROPUERTO",
        "7096B - HELLÍN",
        "7119B - CARAVACA DE LA CRUZ",
        "7145D - CIEZA",
        "7178I - MURCIA",
        "7209 - LORCA",
        "7228 - ALCANTARILLA, BASE AÉREA",
        "7247X - EL PINÓS/PINOSO",
        "7275C - YECLA"
    ];

    dataList = [
        this.$t("aemet.precipitation"),
        this.$t("aemet.windAverageSpeed"),
        this.$t("aemet.maxGustOfWind"),
        this.$t("aemet.maxTemperature"),
        this.$t("aemet.minTemperature"),
        this.$t("aemet.averageTemperature")
    ];

    idemaCode = "";
    loadedIdemaCode = "";
    dates = ["", ""];
    dataUrl = "";
    show = false;
    availableDates = [];
    allData = [];
    dataType = this.dataList[0];
    maxDate;

    initialDate() {
        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 4);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 8);

        this.dates = [
            minDate.toISOString().split("T")[0],
            maxDate.toISOString().split("T")[0]
        ];
    }

    mounted() {
        this.initialDate();
    }

    orderDates() {
        if (this.dates) {
            if (
                new Date(this.dates[0]).getTime() >
                new Date(this.dates[1]).getTime()
            ) {
                this.dates = [this.dates[1], this.dates[0]];
            } else if (
                new Date(this.dates[0]).getTime() <
                new Date(this.dates[1]).getTime()
            ) {
                this.dates = [this.dates[0], this.dates[1]];
            } else if (
                new Date(this.dates[0]).getTime() ===
                    new Date(this.dates[1]).getTime() ||
                !this.dates[1]
            ) {
                this.dates = [this.dates[0], this.dates[0]];
            }
        }
        return "";
    }

    async fetchUrl() {
        this.orderDates();
        try {
            const response = await fetch(
                `https://opendata.aemet.es/opendata/api/valores/climatologicos/diarios/datos/fechaini/${
                    this.dates[0]
                }T00%3A00%3A00UTC/fechafin/${
                    this.dates[1]
                }T23%3A59%3A59UTC/estacion/${
                    this.idemaCode.split(" ")[0]
                }?api_key=${aemetConfig.accessToken}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "cache-control": "no-cache"
                    }
                }
            );
            if (response.ok) {
                const data = await response.json();
                this.dataUrl = data.datos;
                this.fetchData();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(this.$t("aemet.noData").toString());
            }
        }
    }

    async fetchData() {
        this.loadedIdemaCode = this.idemaCode;
        try {
            this.setProgressBar(true);
            const response = await fetch(`${this.dataUrl}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "cache-control": "no-cache"
                }
            });
            if (response.ok) {
                this.show = true;
                const data = await response.json();
                this.allData = data;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(this.$t("aemet.noData").toString());
            }
        } finally {
            this.setProgressBar(false);
        }
    }

    /**
     * Show error message
     * @param {string} error Error message
     * @return {void}
     */
    showError(error: string): void {
        this.setInfoMessage({ shown: true, text: error });
    }
}
